import { useState, useContext } from "react";
import styles from "./Article.module.css";
import { LanguageContext } from "./LanguageContext";

const Article = ({ title, content }) => {
  const { language, translations } = useContext(LanguageContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LINES = 6;

  // Guard clause for undefined content
  if (!content) {
    return (
      <div className={styles.articleContainer}>
        <p className={styles.articleTitle}>{title || "Untitled"}</p>
        <div className={styles.articleContent}>
          <p>No content available</p>
        </div>
      </div>
    );
  }

  // Function to check if content exceeds line limit
  const hasMoreContent = (contentToCheck) => {
    if (!contentToCheck) return false;
    if (Array.isArray(contentToCheck)) {
      return contentToCheck.length > MAX_LINES;
    }
    return contentToCheck.split("\n").length > MAX_LINES;
  };

  // Function to get truncated content
  const getTruncatedContent = (contentToTruncate) => {
    if (!contentToTruncate) return [];
    if (Array.isArray(contentToTruncate)) {
      return contentToTruncate.slice(0, MAX_LINES);
    }
    return contentToTruncate.split("\n").slice(0, MAX_LINES);
  };

  return (
    <div className={styles.articleContainer}>
      <p className={styles.articleTitle}>{title}</p>
      <div className={styles.articleContent}>
        {isExpanded ? (
          // Show full content when expanded
          Array.isArray(content) ? (
            content.map((line, index) => <p key={index}>{line}</p>)
          ) : (
            <p>{content}</p>
          )
        ) : (
          // Show truncated content
          getTruncatedContent(content).map((line, index) => (
            <p key={index}>{line}</p>
          ))
        )}

        {hasMoreContent(content) && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              color: "#afe54f",
              border: "none",
              background: "transparent",
              cursor: "pointer",
              padding: 0,
              marginTop: "8px",
            }}
          >
            {isExpanded
              ? translations["updatesShowLess"]
              : translations["updatesShowMore"]}
          </button>
        )}
      </div>
    </div>
  );
};

export default Article;
