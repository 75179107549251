// Information.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Information.module.css";

const Information = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(
          `../../data/pages/${language}/information.json`
        );
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading information content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  // Create array of features
  const features = Object.keys(content)
    .filter((key) => key.startsWith("feature"))
    .sort((a, b) => {
      // Extract numbers from feature keys for proper sorting
      const numA = parseInt(a.replace("feature", ""));
      const numB = parseInt(b.replace("feature", ""));
      return numA - numB;
    })
    .map((key) => content[key]);

  return (
    <div className={styles.infoPageWrapper}>
      {/* Protection Type */}
      <div className={styles.infoSection}>
        <h2 className={styles.infoTitle}>{content.protectiontype}</h2>
        <p>{content.protectiondesc}</p>
      </div>

      {/* Supported Languages */}
      <div className={styles.infoSection}>
        <h2 className={styles.infoTitle}>{content.supportedlanguage}</h2>
        <ul>
          <li>{content.language1}</li>
          <li>{content.language2}</li>
        </ul>
        <p>{content.supportedlanguagedesc}</p>
      </div>

      {/* Standard Fixes */}
      <div className={styles.infoSection}>
        <h2 className={styles.infoTitle}>{content.featuresdesc}</h2>
        <div className={styles.moduleList}>
          <ul>
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Information;
