const DiscordWidget = ({ serverId, theme = "dark" }) => {
     console.error = () => {};

  return (
    <iframe
      src={`https://discord.com/widget?id=${serverId}&theme=${theme}/`}
      width="263"
      height="500"
      allowtransparency="true"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      title="Discord Widget"
    />
  );
};

export default DiscordWidget;
