import React, { createContext, useState, useEffect } from "react";
import en from "../locales/en/translation.json";
import ru from "../locales/ru/translation.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    // Check localStorage for saved language preference
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    } else {
      // Set default language to English
      changeLanguage("en");
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    if (lang === "en") {
      setTranslations(en);
    } else if (lang === "ru") {
      setTranslations(ru);
    }
    // Save the language preference to localStorage
    localStorage.setItem("language", lang);
  };

  return (
    <LanguageContext.Provider
      value={{ language, translations, changeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};