import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./components/LanguageContext";
import MainLayout from "./components/MainLayout";
import NotFound from "./components/NotFound";
import Home from "./components/Home";
import Price from "./components/pages/Price";
import Module from "./components/pages/Module";
import Information from "./components/pages/Information";
import Launcher from "./components/pages/Launcher";
import Faq from "./components/pages/Faq";
import Contact from "./components/pages/Contact";
import Blacklist from "./components/pages/Blacklist";
import Pay from "./components/pages/Pay";

function App() {

  return (
    <BrowserRouter>
      <LanguageProvider>
        <div className="app">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="/price" element={<Price />} />
              <Route path="/launcher" element={<Launcher />} />
              <Route path="/information" element={<Information />} />
              <Route path="/module" element={<Module />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blacklist" element={<Blacklist />} />
              <Route path="/pay" element={<Pay />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;
