// Blacklist.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Blacklist.module.css";

const BlacklistItem = ({ name, serverName, reason, aliases, labels }) => {
  return (
    <div className={styles.blacklistItem}>
      <h3 className={styles.blacklistName}>{name}</h3>
      <div className={styles.blacklistDetails}>
        <p>
          <strong>{labels.servername}</strong> {serverName}
        </p>
        <p>
          <strong>{labels.reason}</strong> {reason}
        </p>
        {aliases && (
          <p>
            <strong>{labels.names}</strong> {aliases}
          </p>
        )}
      </div>
    </div>
  );
};

const Blacklist = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(
          `../../data/pages/${language}/blacklist.json`
        );
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading blacklist content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  // Helper function to create blacklist items array from content
  const createBlacklistData = () => {
    const items = [];
    let i = 1;
    while (content[`username${i}`]) {
      items.push({
        name: content[`username${i}`],
        serverName: content[`servername${i}`],
        reason: content[`reason${i}`],
        aliases: content[`alias${i}`],
      });
      i++;
    }
    return items;
  };

  const blacklistData = createBlacklistData();

  const labels = {
    servername: content.servername,
    reason: content.reason,
    names: content.names,
  };

  return (
    <div className={styles.blacklistPageWrapper}>
      {/* Header */}
      <div className={styles.blacklistHeader}>
        <h2>{content.info}</h2>
      </div>

      {/* Blacklist Items */}
      <div className={styles.blacklistContainer}>
        {blacklistData.map((item, index) => (
          <BlacklistItem
            key={index}
            name={item.name}
            serverName={item.serverName}
            reason={item.reason}
            aliases={item.aliases}
            labels={labels}
          />
        ))}
      </div>
    </div>
  );
};

export default Blacklist;
