// Contact.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Contact.module.css";
import discord_verify from "../../image/discord_verify.png";

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(`../../data/pages/${language}/contact.json`);
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading contact content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.contactPageWrapper}>
      {/* Warning Section */}
      <div className={styles.warningSection}>
        <p>{content.odinwarning}</p>
      </div>

      {/* Contact Information Section */}
      <div className={styles.contactSection}>
        <div className={styles.contactItem}>
          <h3>{content.discordlink}</h3>
          <a
            href="https://discord.com/users/333655324642770944"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://discord.com/users/333655324642770944
          </a>
        </div>

        <div className={styles.contactItem}>
          <h3>{content.discordname}</h3>
          <p>
            Felix(Beta)#2593{" "}
            <span className={styles.note}>
              ({content.discorddesc})
            </span>
          </p>
        </div>

        <div className={styles.contactItem}>
          <h3>Skype</h3>
          <p>
            gm-felix{" "}
            <span className={styles.note}>
              {content.skypedesc}
            </span>
          </p>
        </div>

        {/* Discord Image */}
        <div className={styles.discordImage}>
          <img src={discord_verify} alt="Discord Verification" />
        </div>
      </div>
    </div>
  );
};

export default Contact;