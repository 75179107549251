import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import { NavLink } from "react-router-dom";

const Menu = () => {
  const { language, translations, changeLanguage } =
    useContext(LanguageContext);

  return (
    <>
      <div className="header-menu-container">
        <nav className="header-menu">
          <div className="header-menu-left">
            <img
              className="headerButton"
              src="https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png"
              alt="English"
              onClick={() => changeLanguage("en")}
            ></img>
            <img
              className="headerButton"
              src="https://cdn.countryflags.com/thumbs/russia/flag-round-250.png"
              alt="Русский"
              onClick={() => changeLanguage("ru")}
            ></img>
            <NavLink className="headerButton" to="/">
              {translations["headerMenuMain"]}
            </NavLink>
            <NavLink className="headerButton" to="/price">
              {translations["headerMenuPrice"]}
            </NavLink>
            <NavLink className="headerButton" to="/launcher">
              {translations["headerMenuLauncher"]}
            </NavLink>
            <NavLink className="headerButton" to="/information">
              {translations["headerMenuInformation"]}
            </NavLink>
            <NavLink className="headerButton" to="/module">
              {translations["headerMenuModule"]}
            </NavLink>
            <NavLink className="headerButton" to="/faq">
              {translations["headerMenuFaq"]}
            </NavLink>
            <NavLink className="headerButton" to="/contact">
              {translations["headerMenuContact"]}
            </NavLink>
            <NavLink className="headerButton" to="/blacklist">
              {translations["headerMenuBlacklist"]}
            </NavLink>
          </div>
          <div className="header-menu-right">
            <NavLink className="payButton" to="/pay">
               {translations["headerMenuPay"]}
            </NavLink>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Menu;
