import { Outlet } from "react-router-dom";
import logo from "../image/logo.jpg";
import Menu from "./Menu";
import Resources from "./Resources";
import DiscordWidget from "./DiscordWidget";
import Footer from "./Footer";
import styles from "./MainLayout.module.css"; 
import { NavLink } from "react-router-dom";

const MainLayout = () => {
  return (
    <div className={styles.mainContainer}>
      <header>
        <NavLink to="/"><img className="main-logo" src={logo} alt="logo" /></NavLink>    
        <Menu />
      </header>
      <div className={styles.contentContainer}>
        <main className={styles.mainContent}>
          <Outlet />
        </main>
        <aside className={styles.sidebar}>
          <Resources />
          <div className={styles.discordContainer}>
            <DiscordWidget serverId="578619976202715185" />
          </div>
        </aside>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
