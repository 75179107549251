import News from "./News";
const Home = () => {
  return (
    <>
      <News />
    </>
  );
};

export default Home;
