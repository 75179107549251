// Module.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Module.module.css";

const ModuleSection = ({ title, modules }) => (
  <div className={styles.moduleSection}>
    <h2 className={styles.moduleTitle}>{title}</h2>
    <div className={styles.moduleGrid}>
      {modules.map((module, index) => (
        <div key={index} className={styles.moduleItem}>
          <h3>{module.name}</h3>
          <p>{module.desc}</p>
        </div>
      ))}
    </div>
  </div>
);

const Module = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(
          `../../data/pages/${language}/module.json`
        );
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading module content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  // Helper function to extract modules for each type
  const getModules = (typePrefix, count) => {
    const modules = [];
    for (let i = 1; i <= count; i++) {
      const name = content[`${typePrefix}modulename${i}`];
      const desc = content[`${typePrefix}moduledesc${i}`];
      if (name && desc) {
        modules.push({ name, desc });
      }
    }
    return modules;
  };

  const type1Modules = getModules("type1", 13);
  const type2Modules = getModules("type2", 14);
  const type3Modules = getModules("type3", 7);
  const type4Modules = getModules("type4", 2);

  return (
    <div className={styles.modulePageWrapper}>
      {/* Basic License Modules */}
      <ModuleSection title={content.type1modulelist} modules={type1Modules} />

      {/* License Type 2 Modules */}
      <ModuleSection title={content.type2modulelist} modules={type2Modules} />

      {/* License Type 3 Modules */}
      <ModuleSection title={content.type3modulelist} modules={type3Modules} />

      {/* License Type 4 Modules */}
      <ModuleSection title={content.type4modulelist} modules={type4Modules} />

      <p className={styles.footnote}>{content.licensetypeinfo}</p>
    </div>
  );
};

export default Module;
