import { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import launcher1 from "../../image/launcher_img1.png";
import launcher2 from "../../image/launcher_img2.png";
import launcher3 from "../../image/launcher_img3.png";
import styles from "./Launcher.module.css";

const Launcher = () => {
  const { language } = useContext(LanguageContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const screenshots = [launcher1, launcher2, launcher3];

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(
          `../../data/pages/${language}/launcher.json`
        );
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading launcher content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  const openModal = (src) => {
    setSelectedImage(src);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedImage(null);
    document.body.style.overflow = "unset";
  };

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  const features = [
    content.feature1,
    content.feature2,
    content.feature3,
    content.feature4,
    content.feature5,
    content.feature6,
    content.feature7,
    content.feature8,
    content.feature9,
  ];

  return (
    <div className={styles.launcherPageWrapper}>
      {/* Introduction */}
      <div className={styles.launcherSection}>
        <h1 className={styles.introText}>{content.odinlauncher}</h1>
      </div>

      {/* Features Section */}
      <div className={styles.launcherSection}>
        <h2 className={styles.sectionTitle}>{content.mainfeatures}</h2>
        <div className={styles.featureGrid}>
          {features.map((feature, index) => (
            <div key={index} className={styles.featureItem}>
              <p>{feature}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Screenshots Section */}
      <div className={styles.launcherSection}>
        <h2 className={styles.sectionTitle}>{content.screenshots}</h2>
        <div className={styles.screenshotGrid}>
          {screenshots.map((src, index) => (
            <div
              key={index}
              className={styles.screenshotItem}
              onClick={() => openModal(src)}
            >
              <img src={src} alt={`Launcher Screenshot ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {selectedImage && (
        <div className={styles.modal} onClick={closeModal}>
          <div className={styles.modalContent}>
            <img src={selectedImage} alt="Enlarged screenshot" />
            <button className={styles.closeButton} onClick={closeModal}>
              ×
            </button>
          </div>
        </div>
      )}

      {/* Price Section */}
      <div className={styles.launcherSection}>
        <h2 className={styles.sectionTitle}>{content.price}</h2>
        <a href="/Price" className={styles.priceLink}>
          50$
        </a>
      </div>
    </div>
  );
};

export default Launcher;
