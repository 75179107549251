// Faq.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Faq.module.css";

const Faq = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(`../../data/pages/${language}/faq.json`);
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading FAQ content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  // Helper function to create FAQ items array from content
  const createFaqItems = () => {
    const items = [];
    let i = 1;
    while (content[`q${i}`] && content[`a${i}`]) {
      items.push({
        question: content[`q${i}`],
        answer: content[`a${i}`],
      });
      i++;
    }
    return items;
  };

  const faqItems = createFaqItems();

  return (
    <div className={styles.faqPageWrapper}>
      {/* Introduction */}
      <div className={styles.faqIntro}>
        <p>{content.faqend}</p>
      </div>

      {/* FAQ Items */}
      <div className={styles.faqContainer}>
        {faqItems.map((item, index) => (
          <div key={index} className={styles.faqItem}>
            <div className={styles.question}>
              <span className={styles.questionMark}>Q:</span>
              {item.question}
            </div>
            <div className={styles.answer}>
              <span className={styles.answerMark}>A:</span>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
