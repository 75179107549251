// Price.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Price.module.css";

const Price = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(
          `../../data/pages/${language}/price.json`
        );
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading price content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.pricePageWrapper}>
      {/* Warning section */}
      <div className={styles.pricePageWarning}>
        <p>
          <span>{content.odinwarning}</span>
        </p>
      </div>

      {/* License Types section */}
      <h2 className={styles.licensesTitle}>{content.licensetypes}</h2>

      {/* License items */}
      <div className={styles.pricePageContainer}>
        <div className={styles.licenseItem}>
          <div className={styles.pricePageLicenseType}>
            <h3>{content.licensetype1}</h3>
          </div>
          <div className={styles.pricePageLicenseExtra}>
            <p>{content.licensetype1month}</p>
            <p>{content.licensetype1subprice}</p>
            <p>{content.licensetype1subfree}</p>
          </div>
          <div className={styles.pricePageLicensePrice}>
            <p>300$</p>
          </div>
        </div>

        <div className={styles.licenseItem}>
          <div className={styles.pricePageLicenseType}>
            <h3>{content.licensetype2}</h3>
          </div>
          <div className={styles.pricePageLicenseExtra}>
            <p>{content.licensetype2month}</p>
            <p>{content.licensetype2subprice}</p>
            <p>{content.licensetype2subfree}</p>
          </div>
          <div className={styles.pricePageLicensePrice}>
            <p>400$</p>
          </div>
        </div>

        <div className={styles.licenseItem}>
          <div className={styles.pricePageLicenseType}>
            <h3>{content.licensetype3}</h3>
          </div>
          <div className={styles.pricePageLicenseExtra}>
            <p>{content.licensetype3month}</p>
            <p>{content.licensetype3subprice}</p>
            <p>{content.licensetype3subfree}</p>
          </div>
          <div className={styles.pricePageLicensePrice}>
            <p>500$</p>
          </div>
        </div>

        <div className={styles.licenseItem}>
          <div className={styles.pricePageLicenseType}>
            <h3>{content.licensetype4}</h3>
          </div>
          <div className={styles.pricePageLicenseExtra}>
            <p>{content.licensetype4month}</p>
            <p>{content.licensetype4subprice}</p>
            <p>{content.licensetype4subfree}</p>
          </div>
          <div className={styles.pricePageLicensePrice}>
            <p>600$</p>
          </div>
        </div>

        <div className={styles.licenseItem}>
          <div className={styles.pricePageLicenseType}>
            <h3>{content.licensetypeTest}</h3>
          </div>
          <div className={styles.pricePageLicenseExtra}>
            <p>{content.licensetypeTestMonth}</p>
            <p>{content.licensetypeTestSubprice}</p>
            <p>{content.licensetypeTestSubfree}</p>
          </div>
          <div className={styles.pricePageLicensePrice}>
            <p>0$</p>
          </div>
        </div>

        {/* Payment Methods section */}
        <div className={styles.pricePageSection}>
          <h2>{content.paymentmethodlicense}:</h2>
          <p>- Wise.com (TransferWise.com)</p>
          <p>- Crypto USDT TRC20 (check TVC...6ct)</p>

          <h2>{content.paymentmethodsub}</h2>
          <p>- Crypto USDT TRC20 (check TVC...6ct)</p>
          <p>- PayPal (check dm...ko@gmail.com)</p>
          <p>- AnyPay</p>
          <p>- Payeer</p>
        </div>
      </div>
    </div>
  );
};

export default Price;
