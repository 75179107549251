import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import styles from "./Resources.module.css";
import ChatLeftFill from "../image/chat-left-fill.svg";
import YouTube from "../image/youtube.svg";
import FileEarmarkArrowDownFill from "../image/file-earmark-arrow-down-fill.svg";

const Resources = () => {
    const { language, translations } = useContext(LanguageContext);
  return (
    <>
      <div className={styles.resourcesContainer}>
        <div className={styles.resourcesTitle}>
          <p>{translations["resourcesTitle"]}</p>
        </div>
        <div className={styles.resourcesLinks}>
          <a
            href="https://rfdev.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.resourcesIcon}
              src={ChatLeftFill}
              alt={translations["resourcesRFDevForum"]}
            />{" "}
            <span>{translations["resourcesRFDevForum"]}</span>
          </a>
          <a
            href="https://www.youtube.com/channel/UC6cVM8zhAvAZbr4-jKYa41A"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.resourcesIcon}
              src={YouTube}
              alt={translations["resourcesYouTube"]}
            />{" "}
            <span>{translations["resourcesYouTube"]}</span>
          </a>

          <a
            href="http://www.mediafire.com/file/2hxg0gzvlh9gdvn/Default_2232_Client.7z/file"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.resourcesIcon}
              src={FileEarmarkArrowDownFill}
              alt={translations["resourcesDefaultClient"]}
            />{" "}
            <span>{translations["resourcesDefaultClient"]}</span>
          </a>
          <a
            href="https://www.mediafire.com/file/e19rrbx4gqvq01e/Default_2232_Server.7z/file"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.resourcesIcon}
              src={FileEarmarkArrowDownFill}
              alt={translations["resourcesDefaultServer"]}
            />{" "}
            <span>{translations["resourcesDefaultServer"]}</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default Resources;
