// Pay.jsx
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../LanguageContext";
import styles from "./Pay.module.css";
import payeerLogo from "../../image/payeer.png";

const Pay = () => {
  const { language } = useContext(LanguageContext);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  const anypayButtons = [
    {
      months: 1,
      amount: 10,
      sign: "0c9a7a2925c9cec8a87ee6ca0f275928",
    },
    {
      months: 2,
      amount: 20,
      sign: "6d5fcd31b60c7f56d041f0e652cf8687",
    },
    {
      months: 3,
      amount: 30,
      sign: "74d794b5153a943ddeda22c44dbba927",
    },
    {
      months: 5,
      amount: 50,
      sign: "393513b84bc7f35fe20aa7624d3fa2a8",
    },
  ];

  useEffect(() => {
    const loadContent = async () => {
      try {
        const response = await import(`../../data/pages/${language}/pay.json`);
        setContent(response.default);
        setLoading(false);
      } catch (error) {
        console.error("Error loading pay content:", error);
        setLoading(false);
      }
    };

    loadContent();
  }, [language]);

  if (loading || !content) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.payPageWrapper}>
      {/* Warning Section */}
      <div className={styles.warningSection}>
        <h2>{content.paywarning}</h2>
        <p>{content.desc1}</p>
        <p>{content.desc2}</p>
        <p>{content.desc3}</p>
      </div>

      {/* Payment Methods Section */}
      <div className={styles.paymentSection}>
        {/* Payeer Button */}
        <div className={styles.payeerContainer}>
          <a
            href="https://odin-secure.com/pay.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={payeerLogo} alt="Pay with Payeer" />
          </a>
        </div>

        {/* AnyPay Buttons */}
        <div className={styles.anypayContainer}>
          {anypayButtons.map((button, index) => (
            <div key={index} className={styles.anypayButton}>
              <iframe
                src={`https://anypay.io/widget/button?merchant_id=10288&pay_id=1&amount=${button.amount}&currency=USD&desc=${button.months}%20Month%20Subscription&text=GET%20${button.months}%20Month&size=large&theme=1&sign=${button.sign}`}
                scrolling="no"
                width="320"
                height="63"
                frameBorder="0"
                title={`${button.months} Month Subscription`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pay;