// Function to import all news files for a specific language
export const loadNewsFiles = async (language) => {
  // Use webpack's require.context to get all JSON files in the specified directory
  const context = require.context("../data/news", true, /\.json$/);
  const newsFiles = context
    .keys()
    .filter((key) => key.includes(`/${language}/`));

  // Load and parse each file
  const newsData = await Promise.all(
    newsFiles.map(async (filename) => {
      const file = await context(filename);
      return {
        ...file,
        // Extract date from filename (28102024.json -> 28102024)
        fileDate: filename.split("/").pop().replace(".json", ""),
      };
    })
  );

  // Sort by date (newest first)
  return newsData.sort((a, b) => {
    // Convert DDMMYYYY to YYYY-MM-DD format for proper Date parsing
    const formatDate = (fileDateStr) => {
      const day = fileDateStr.substring(0, 2);
      const month = fileDateStr.substring(2, 4);
      const year = fileDateStr.substring(4, 8);
      return `${year}-${month}-${day}T00:00:00`; // Adding time component
    };

  const dateA = new Date(formatDate(a.fileDate));
  const dateB = new Date(formatDate(b.fileDate));

    return dateB - dateA;
  });
};
