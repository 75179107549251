import Article from "./Article";
import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import { loadNewsFiles } from "../utils/NewsLoader";
import styles from "./News.module.css";

const News = () => {
  const { language, translations } = useContext(LanguageContext);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 10;

  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const data = await loadNewsFiles(language);
        setNewsData(data);
      } catch (error) {
        console.error("Error loading news:", error);
      }
      setLoading(false);
    };

    fetchNews();
  }, [language]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Calculate pagination
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = newsData.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );
  const totalPages = Math.ceil(newsData.length / articlesPerPage);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className={styles.newsHeaderContainer}>
        <p>{translations["updatesTitle"]}</p>
      </div>
      <div className={styles.newsContainer}>
        {currentArticles.map((article) => (
          <Article
            key={article.fileDate}
            title={article.name}
            content={article.content}
          />
        ))}
      </div>
      {totalPages > 1 && (
        <div className={styles.paginationContainer}>
          {currentPage > 1 && (
            <button
              onClick={() => paginate(currentPage - 1)}
              className={styles.paginationButton}
            >
              &lt;
            </button>
          )}

          {[...Array(totalPages)].map((_, index) => {
            const pageNumber = index + 1;
            // Show current page, first and last pages, and one page before and after current
            if (
              pageNumber === 1 ||
              pageNumber === totalPages ||
              (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
            ) {
              return (
                <button
                  key={pageNumber}
                  onClick={() => paginate(pageNumber)}
                  className={`${styles.paginationButton} ${
                    currentPage === pageNumber ? styles.activePage : ""
                  }`}
                >
                  {pageNumber}
                </button>
              );
            } else if (
              pageNumber === currentPage - 2 ||
              pageNumber === currentPage + 2
            ) {
              return (
                <span key={pageNumber} className={styles.paginationDots}>
                  ...
                </span>
              );
            }
            return null;
          })}

          {currentPage < totalPages && (
            <button
              onClick={() => paginate(currentPage + 1)}
              className={styles.paginationButton}
            >
              &gt;
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default News;
